<template>
  <div class="w-screen max-w-sm p-4 xl:flex xl:flex-1 xl:flex-col xl:justify-between xl:w-full xl:max-w-full only-xl:p-7-5 only-md:p-7-5 sm:p-4">
    <div>
      <div class="flex justify-center">
        <div class="text-center text-xl33 font-bold mx-auto md:text-xl24">
          {{$t('invites.about_invite_codes')}}
        </div>
      </div>
      <div class="mt-5 font-semibold">{{$t('invites.every_user')}}</div>
      <div class="mt-5 font-semibold">{{$t('invites.these_rules')}}</div>
    </div>
  
    <ButtonV2
      @onClick="hideModal"
      :label="$t('navigation.got_it')"
      testId="btn-gotit"
      class="mt-7-5"
      wide
    />
  </div>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2';

export default {
  name: 'AboutInviteCodes',
  components: {
    ButtonV2,
  },

  async mounted() {
    await this.$store.dispatch('events/track', {
      event: 'INVITE_MORE_INVITES_ALERT_VIEWED',
    });
  },
};
</script>

<style scoped>
</style>
